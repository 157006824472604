import React, {useState} from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const encode = (data) => {
  return Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&')
}

const ContactPage = () => {
  const [state, setState] = useState({success: false, error: false, loading: false})
  const [form, setForm] = useState({'bot-field': '', name: '', email: '', message: ''})

  const handleSubmit = event => {
    event.preventDefault()
    setState({...state, loading: true})

    // eslint-disable-next-line no-undef
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': 'contact',
        ...form
      })
    })
      .then(() => setState({...state, loading: false, success: true}))
      .catch(() => setState({...state, loading: false, success: false, error: 'There was an error submitting your form, please try again'}))
  }

  const handleChange = ({target}) => {
    const {name, value} = target
    setForm({...form, [name]: value})
  }

  return (
    <Layout>
      <SEO
        title='Contact'
        description=''
        keywords={['contact', 'contact us']} />

      <section className='relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
        <div className='text-center'>
          <h1 className='text-3xl leading-9 tracking-tight font-raleway font-bold text-gray-900 sm:text-4xl sm:leading-10'>Contact</h1>
          <p className='mt-3 max-w-4xl mx-auto text-xl leading-7 text-blue-900 opacity-60 sm:mt-4'>&nbsp;</p>
        </div>

        <form className='w-full max-w-lg mx-auto' method='post' action='/contact-success/' name='contact' netlify-honeypot='bot-field' data-netlify='true' onSubmit={handleSubmit}>
          <input type='hidden' name='bot-field' />
          <div className='flex flex-wrap mb-6'>
            <div className='w-full px-3'>
              <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='name'>Name</label>
              <input className='appearance-none border-gray-500 block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none' id='name' name='name' type='text' onChange={handleChange} />
            </div>
          </div>
          <div className='flex flex-wrap mb-6'>
            <div className='w-full px-3'>
              <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='email'>E-mail</label>
              <input className='appearance-none border-gray-500 block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none' id='email' name='email' type='email' onChange={handleChange} />
            </div>
          </div>
          <div className='flex flex-wrap mb-6'>
            <div className='w-full px-3'>
              <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='message'>Message</label>
              <textarea className='appearance-none border-gray-500 block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none h-48' id='message' name='message' onChange={handleChange} />
            </div>
          </div>
          <div className='flex flex-col px-3'>
            {!state.success && <button className='block w-full py-3 rounded font-bold text-white tracking-wider bg-indigo-600 hover:bg-black' type='submit'>{state.loading ? 'Sending' : 'Send'}</button>}
            {state.success && <span className='block w-full py-3 rounded font-bold text-white text-center tracking-wider bg-green-600' type='submit'>Successfully sent</span>}
            {state.error && <span className='block w-full py-3 rounded font-bold text-white text-center tracking-wider bg-red-600' type='submit'>{state.error}</span>}
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default ContactPage
